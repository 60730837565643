import { createRouter,createWebHashHistory } from "vue-router";
import store from '@/store'
import util from '@/util/util'

// 主后台管理
// 城市界面
import admin from '@/pages/admin/home.vue'

// 路由规则
const routes = [
	{
		path: '/',
		redirect: '/login',
        meta: {
            title: '登录'
        }
	},
    {
        name: '后台登录',
        path:'/login',
        component: () => import('@/pages/login/index.vue'),
        meta: {
            title: '登录'
        }
    },
    // 主后台
    {

        meta: {
            title: '主后台-管理端'
        },
        path:'/admin',
        component:admin,
        children: [
            {
                // '后台首页',
                path: 'index/index',
                component: () => import('@/pages/admin/index/index.vue')
            },

            // 文件
            {
                // '文件',
                path: 'file/file',
                component: () => import('@/pages/admin/file/file.vue')
            },

            // 系统管理管理
            
            // 导航管理
                {
                    // '主后台',
                    path: 'config/nav/nav',
                    component: () => import('@/pages/admin/config/nav/nav.vue')
                },
                {
                    // '代理',
                    path: 'config/nav/dai',
                    component: () => import('@/pages/admin/config/nav/dai.vue')
                },
                {
                    // '城市',
                    path: 'config/nav/cheng',
                    component: () => import('@/pages/admin/config/nav/cheng.vue')
                },
                {
                    // '仓库',
                    path: 'config/nav/cang',
                    component: () => import('@/pages/admin/config/nav/cang.vue')
                },
                {
                    // '供应商',
                    path: 'config/nav/gong',
                    component: () => import('@/pages/admin/config/nav/gong.vue')
                },
            // 导航管理

            // 管理员
                {
                    // '管理员',
                    path: 'user/user',
                    component: () => import('@/pages/admin/user/user.vue')
                },
                {
                    // '日志',
                    path: 'user/log',
                    component: () => import('@/pages/admin/user/log.vue')
                },
                {
                    // '权限',
                    path: 'user/rank',
                    component: () => import('@/pages/admin/user/rank.vue')
                },
                {
                    // '权限',
                    path: 'user/rankPhone',
                    component: () => import('@/pages/admin/user/rankPhone.vue')
                },
            // 管理员

            // 代理管理
                {
                    // '代理列表',
                    path: 'agency/agency',
                    component: () => import('@/pages/admin/agency/agency.vue')
                },
                {
                    // '管理员',
                    path: 'agency/user',
                    component: () => import('@/pages/admin/agency/user.vue')
                },
            // 代理管理

            // 供应商管理
                {
                    // '供应商列表',
                    path: 'supply/supply',
                    component: () => import('@/pages/admin/supply/supply.vue')
                },
                {
                    // '管理员',
                    path: 'supply/user',
                    component: () => import('@/pages/admin/supply/user.vue')
                },
            // 供应商管理
            
        ],
    },

    // 代理
    {

        meta: {
            title: '代理-管理端'
        },
        path:'/agency',
        component: () => import('@/pages/agency/home.vue'),
        children: [
            {
                // '后台首页',
                path: 'index/index',
                component: () => import('@/pages/agency/index/index.vue')
            },

            // 文件
            {
                // '文件',
                path: 'file/file',
                component: () => import('@/pages/agency/file/file.vue')
            },

            // 系统管理管理

            // 管理员
                {
                    // '管理员',
                    path: 'user/user',
                    component: () => import('@/pages/agency/user/user.vue')
                },
                {
                    // '日志',
                    path: 'user/log',
                    component: () => import('@/pages/agency/user/log.vue')
                },
                {
                    // '权限',
                    path: 'user/rank',
                    component: () => import('@/pages/agency/user/rank.vue')
                },
                {
                    // '权限',
                    path: 'user/rankPhone',
                    component: () => import('@/pages/agency/user/rankPhone.vue')
                },
            // 管理员

            // 城市
                {
                    // '城市',
                    path: 'area/area',
                    component: () => import('@/pages/agency/area/area.vue')
                },
                {
                    // '日志',
                    path: 'area/user',
                    component: () => import('@/pages/agency/area/user.vue')
                },
            // 城市

            // 城市
            {
                // '城市',
                path: 'granary/granary',
                component: () => import('@/pages/agency/granary/granary.vue')
            },
            {
                // '日志',
                path: 'granary/user',
                component: () => import('@/pages/agency/granary/user.vue')
            },
        // 城市
            
        ],
    },

    // 城市
    {

        meta: {
            title: '门店-管理端'
        },
        path:'/area',
        component: () => import('@/pages/area/home.vue'),
        children: [
            {
                // '后台首页',
                path: 'index/index',
                component: () => import('@/pages/area/index/index.vue')
            },

            // 文件
            {
                // '文件',
                path: 'file/file',
                component: () => import('@/pages/area/file/file.vue')
            },

            // 系统管理管理

            // 管理员
                {
                    // '管理员',
                    path: 'user/user',
                    component: () => import('@/pages/area/user/user.vue')
                },
                {
                    // '日志',
                    path: 'user/log',
                    component: () => import('@/pages/area/user/log.vue')
                },
                {
                    // '权限',
                    path: 'user/rank',
                    component: () => import('@/pages/area/user/rank.vue')
                },
                {
                    // '权限',
                    path: 'user/rankPhone',
                    component: () => import('@/pages/area/user/rankPhone.vue')
                },
                {
                    // '权限',
                    path: 'user/tiList',
                    component: () => import('@/pages/area/user/tiList.vue')
                },
            // 管理员

            // 产品管理
                {
                    // '产品库',
                    path: 'goods/goods',
                    component: () => import('@/pages/area/goods/goods.vue')
                },
                {
                    // '分类',
                    path: 'goods/types',
                    component: () => import('@/pages/area/goods/types.vue')
                },
                {
                    // '品牌',
                    path: 'goods/pin',
                    component: () => import('@/pages/area/goods/pin.vue')
                },
                {
                    // '单位',
                    path: 'goods/keep/log',
                    component: () => import('@/pages/area/goods/keepGoodsLog.vue')
                },
            // 产品管理

        ],
    },

    // 仓库
    {

        meta: {
            title: '仓库-管理端'
        },
        path:'/granary',
        component: () => import('@/pages/granary/home.vue'),
        children: [
            {
                // '后台首页',
                path: 'index/index',
                component: () => import('@/pages/granary/index/index.vue')
            },

            // 文件
            {
                // '文件',
                path: 'file/file',
                component: () => import('@/pages/granary/file/file.vue')
            },

            // 系统管理管理

            // 管理员
                {
                    // '管理员',
                    path: 'user/user',
                    component: () => import('@/pages/granary/user/user.vue')
                },
                {
                    // '日志',
                    path: 'user/log',
                    component: () => import('@/pages/granary/user/log.vue')
                },
                {
                    // '权限',
                    path: 'user/rank',
                    component: () => import('@/pages/granary/user/rank.vue')
                },
                {
                    // '权限',
                    path: 'user/rankPhone',
                    component: () => import('@/pages/granary/user/rankPhone.vue')
                },
            // 管理员
            
        ],
    },

    // 供应商
    {

        meta: {
            title: '供应商-管理端'
        },
        path:'/supply',
        component: () => import('@/pages/supply/home.vue'),
        children: [
            {
                // '后台首页',
                path: 'index/index',
                component: () => import('@/pages/supply/index/index.vue')
            },

            // 文件
            {
                // '文件',
                path: 'file/file',
                component: () => import('@/pages/supply/file/file.vue')
            },

            // 系统管理管理

            // 管理员
                {
                    // '管理员',
                    path: 'user/user',
                    component: () => import('@/pages/supply/user/user.vue')
                },
                {
                    // '日志',
                    path: 'user/log',
                    component: () => import('@/pages/supply/user/log.vue')
                },
                {
                    // '权限',
                    path: 'user/rank',
                    component: () => import('@/pages/supply/user/rank.vue')
                },
                {
                    // '权限',
                    path: 'user/rankPhone',
                    component: () => import('@/pages/supply/user/rankPhone.vue')
                },
            // 管理员

            // 加工
                {
                    // '加工',
                    path: 'order/order/:float',
                    component: () => import('@/pages/supply/order/order.vue')
                },
            // 加工

            // 报价
                {
                    // '任务',
                    path: 'offer/task',
                    // path: 'offer/task/:state/',
                    component: () => import('@/pages/supply/offer/task.vue')
                },
                {
                    // '任务',
                    path: 'offer/rule',
                    component: () => import('@/pages/supply/offer/rule.vue')
                },
            // 报价

        ],
    },
]

const router = createRouter({
    history:createWebHashHistory(),
    routes
})

var setLogin = function(fun = ''){
    store.state.user = 0
    localStorage.setItem('user',0);
    store.state.token = 0
    localStorage.setItem('token',0);
    store.state.port = 0
    localStorage.setItem('port',0);
    store.state.rank = 0
    localStorage.setItem('rank',0);
    store.state.nav = 0
    localStorage.setItem('nav',0);
    fun ? fun() : '' ;
}

// 端口 1总后台2代理3城市4仓库5供应商
router.beforeEach(async (to, from, next) => {
    console.log('to',to.path)
    console.log('from',from.fullPath)

    localStorage.setItem('lastVisitedRoute', from.fullPath);

    if (to.meta.title) {
        document.title = to.meta.title
    }

    var path = to.path.split('/');
    var port_name = '';
    if(path.length >= 2){
        port_name = path[1];
    }

    if(to.path == '/login'){
        // loginUrl();
        // next();
        
        util.loginIs(function(isLogin){

            if(isLogin){

                next(util.loginUrl(true));
                
            }else{

                next();

            }
        },false)

    }else{
        util.loginIs(function(isLogin){

            if(isLogin){

                var urlKey = to.path.replace(/\//g,'_');
                console.log('urlKey',urlKey)
                console.log('urlKey',port_name)
                console.log('urlKey',store.state.rank)
                if (typeof store.state.rank[port_name][urlKey] !== 'undefined') {
                    store.state.rankKey = urlKey
                    store.state.rankBut = store.state.rank[port_name][urlKey]
                    next();
                }else{

                    if(!store.state.rank[port_name] || !store.state.rank[port_name].length){
                        setLogin(function(){
                            next('/login');
                        })
                    }else{
                        alert('您没有权限！！！');
                        next(false);
                    }
                }
                
            }else{

                alert('您还没有登录, 请先登录');
                store.state.user = 0
                store.state.token = 0
                next('/login');

            }
        },false)
        
    }
})

export default router