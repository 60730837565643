<template>
  <div style="height: 100%;">
	<div class="picMagnify" :class="{picMagnifyLeft:leftRight,picMagnifyRight:!leftRight}" style="z-index: 99999999;" v-if="imgOff">
		<img class="picMagnifyImg" :src="src">
	</div>
    <router-view></router-view>
  </div>
</template>
  
<script>
	export default {
		name: 'App',

		// setup() {  
		// 	// const route = useRoute();  
		
		// 	onBeforeRouteLeave((to, from, next) => {  
		// 		// 在路由离开之前保存当前路由信息  
		// 		localStorage.setItem('lastVisitedRoute', from.path);  
		// 		next();  
		// 	});  
		
		// 	// 可以在这里添加其他逻辑...  
		// },

		data() {
			return {
				leftRight:true,
				imgOff:false,
				src:''
			}
		},

		created() {
			// 同步缓存数据
			this.saveState()
		},

		mounted(){
			
		},

		methods: {

			imgShow(src = '',leftRight = 1){
				this.imgOff = true
				this.leftRight = leftRight
				this.src = src
			},

			imgHide(){
				this.imgOff = false
			},

			// 同步缓存数据
			saveState() {

				// 在页面加载时读取sessionStorage
				if (sessionStorage.getItem('store')) {
					this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
				}

				// 在页面刷新时将store保存到sessionStorage里
				window.addEventListener('beforeunload', () => {
					sessionStorage.setItem('store', JSON.stringify(this.$store.state))
				})

				this.util.getLogin();

				this.$store.state.isMobile = this.isMobileFun();
				console.log('是否手机',this.$store.state.isMobile)
			},

			isMobileFun() {
				// 这里使用了一个简单的正则表达式来判断userAgent中是否包含常见的移动设备字符串  
				// 注意：这个正则表达式可能需要根据实际情况进行调整  
				const ua = navigator.userAgent.toLowerCase();  
				const isAndroid = /android/.test(ua);  
				const isiOS = /iphone|ipad|ipod/.test(ua);  
				// 也可以添加对Windows Phone、BlackBerry等其他移动操作系统的检测  
				// 但这里为了示例简洁，只检测Android和iOS  
				return isAndroid || isiOS;  
			}
		},
	}
</script>


<style lang="less">
  // 全局css
	@import '@/style/global.less';

	#app {
		height: 100vh;
		background: #f1f1f1;
	}

	.el-row{
		width: 100%;
	}

	.el-table__inner-wrapper{
		height: 100% !important;
	}

	.el-table__body-wrapper{

		.butList{
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;

			.el-button+.el-button{
				margin-left: 0px !important;
				margin-top: 5px;
				margin-bottom: 5px;
			}
		}
	}

	/* 图片放大 */
    .picMagnify{
        width:calc(50% - 15px);
        height: calc(90% - 10px);
        position:fixed;
        top:5%;
        z-index: 9999;
        background:rgba(0,0,0,0.5);
        padding:5px;
        // display:none;
    }

    .picMagnifyImg{
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }

    .picMagnifyLeft{
        left:5px;
    }

    .picMagnifyRight{
        right:5px;
    }

    .showPic{
        cursor:pointer;
    }

    .showPicCursor{
        cursor:pointer;
    }
    /* 图片放大 end */
  
</style>

